@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Titillium+Web:200,400);
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 2px solid #fd7014;
  margin: 0 0 10px;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  font-weight: bold;
}

.react-tabs__tab {
  display: inline-block;
  border: 2px solid transparent;
  border-bottom: none;
  bottom: 0px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
}

.react-tabs__tab--selected {
  background: transparent;
  border-bottom: 3px solid #fd7014;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}

.react-tabs__tab:focus {
  box-shadow: 0 0 5px #fd7014;
  border-color: #fd7014;
  outline: none;
}

.react-tabs__tab:focus:after {
  content: "";
  position: absolute;
  height: 3px;
  left: -4px;
  right: -4px;
  bottom: -5px;
  background: #fd7014;
}

.react-tabs__tab-panel {
  display: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}


/* Fonts */
/* General */
/* Navbar */

/* Dark Colors
#222831 - dark
#393e46 - lighter dark
#fd7014 - orange
#eeeeee - white */

/* Light Colors
#fffef6 - off white
#fd7014 - orange
#0e3047 - darkblue
#10828c - teal */

